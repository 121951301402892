exports.components = {
  "component---src-pages-about-ms-index-js": () => import("./../../../src/pages/about-ms/index.js" /* webpackChunkName: "component---src-pages-about-ms-index-js" */),
  "component---src-pages-about-ms-rrms-and-ppms-js": () => import("./../../../src/pages/about-ms/rrms-and-ppms.js" /* webpackChunkName: "component---src-pages-about-ms-rrms-and-ppms-js" */),
  "component---src-pages-about-ocrevus-before-using-ocrevus-js": () => import("./../../../src/pages/about-ocrevus/before-using-ocrevus.js" /* webpackChunkName: "component---src-pages-about-ocrevus-before-using-ocrevus-js" */),
  "component---src-pages-about-ocrevus-index-js": () => import("./../../../src/pages/about-ocrevus/index.js" /* webpackChunkName: "component---src-pages-about-ocrevus-index-js" */),
  "component---src-pages-compass-patient-assistance-program-index-js": () => import("./../../../src/pages/compass-patient-assistance-program/index.js" /* webpackChunkName: "component---src-pages-compass-patient-assistance-program-index-js" */),
  "component---src-pages-compass-patient-assistance-program-resources-js": () => import("./../../../src/pages/compass-patient-assistance-program/resources.js" /* webpackChunkName: "component---src-pages-compass-patient-assistance-program-resources-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-homepage-index-js": () => import("./../../../src/pages/patient-homepage/index.js" /* webpackChunkName: "component---src-pages-patient-homepage-index-js" */),
  "component---src-pages-patient-loginpage-index-js": () => import("./../../../src/pages/patient-loginpage/index.js" /* webpackChunkName: "component---src-pages-patient-loginpage-index-js" */),
  "component---src-pages-result-index-js": () => import("./../../../src/pages/result/index.js" /* webpackChunkName: "component---src-pages-result-index-js" */),
  "component---src-pages-side-effects-index-js": () => import("./../../../src/pages/side-effects/index.js" /* webpackChunkName: "component---src-pages-side-effects-index-js" */),
  "component---src-pages-treatment-with-ocrevus-index-js": () => import("./../../../src/pages/treatment-with-ocrevus/index.js" /* webpackChunkName: "component---src-pages-treatment-with-ocrevus-index-js" */),
  "component---src-pages-treatment-with-ocrevus-what-to-expect-js": () => import("./../../../src/pages/treatment-with-ocrevus/what-to-expect.js" /* webpackChunkName: "component---src-pages-treatment-with-ocrevus-what-to-expect-js" */),
  "component---src-templates-redirect-template-js": () => import("./../../../src/templates/redirect-template.js" /* webpackChunkName: "component---src-templates-redirect-template-js" */)
}

