import React from 'react';

export const AppStateContext = React.createContext();
const isBrowser = typeof window !== 'undefined';

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: null,
      page: null,
      section: null,
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const language = localStorage.getItem('ca.ocrevus.lang');
    if (language && ['english', 'french'].includes(language)) {
      this.setState({ language: language });
    } else {
      this.setState({ language: 'english' });
    }

    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    this.setState({ isLoggedIn });

    this.loadLanguage(language);
  }

  setPage = (page) => {
    this.setState({
      page: page,
    });
  };

  setSection = (section) => {
    this.setState({
      section: section,
    });
  };

  setLanguage = (language) => {
    if (!language || !['english', 'french'].includes(language)) {
      language = 'english';
    }

    this.setState({
      language: language
    });

    localStorage.setItem('ca.ocrevus.lang', language);
    this.loadLanguage(language);
  };

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === 'french' ? 'fr-ca' : 'en';
      window.OneTrust.changeLanguage(langCode);
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage;
      setTimeout(function () {
        loadLanguage(language, retryCount + 1);
      }, (2 + retryCount) * 50);
    }
  }

  toggleLanguage = () => {
    const language = this.state.language === 'french' ? 'english' : 'french';
    this.setState({
      language: language,
    });
    localStorage.setItem('ca.ocrevus.lang', language);
    this.loadLanguage(language);
  };

  setIsLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn });
    localStorage.setItem('isLoggedIn', isLoggedIn);
  };

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      setPage: this.setPage,
      setSection: this.setSection,
      toggleLanguage: this.toggleLanguage,
      setIsLoggedIn: this.setIsLoggedIn,
    };
  };

  render() {
    return <AppStateContext.Provider value={this.getValue()}>{this.props.children}</AppStateContext.Provider>;
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>;
}
